<ng-container *ngIf="!response; else responseExists">
  <div class="alert alert-warning" role="alert">
    Geen antwoord ontvangen!
  </div>
</ng-container>

<ng-template #responseExists>

  <div *ngIf="!response.IsOk; else responseOk" class="alert alert-danger" role="alert">
    Er is een fout opgetreden!
    {{response.ErrorMessage}}
  </div>

  <ng-template #responseOk>
    <table class="table table-striped table-sm small">
      <thead>
        <tr>
          @if (!withoutFirstColumn) {
          <th></th>
          }
          <th>Artikel</th>
          <th>Prijs</th>
          <th>Depotnaam</th>
          <th>Depot ID</th>
          <th class="text-end">Voorraad</th>
          <th class="text-center">Extra</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of response.Items | keyvalue">
          <tr>
            @if (!withoutFirstColumn) {
            <td>
              {{item.key}}<br />
              <span *ngIf="item.value.Properties['SupplierId']" class="text-low">SupplierId:
                {{item.value.Properties['SupplierId']}}</span>
            </td>
            }
            <td>
              <span class="fw-bold pointer"
                (click)="searchAction.emit('$'+item.value.ItemNumber)">{{item.value.ItemNumber}}</span><span
                *ngIf="item.value.ItemGroup" class="ms-1 text-low">({{item.value.ItemGroup}})</span><br />
              <span *ngIf="item.value.Description">{{item.value.Description}}<br /></span>
              <span *ngFor="let property of getProperties(item.value)" class="text-low">{{property}}:
                {{item.value.Properties[property]}}<br /></span>
              <span *ngIf="item.value.Remark">{{item.value.Remark}}</span>
            </td>
            <td class="price-col">
              <div class="row g-0">
                @if (item.value.PriceInfo.GrossPrice) {
                <div class="col-5">bruto</div>
                <div class="col-7 text-end">{{item.value.PriceInfo.GrossPrice | price}}</div>
                }
                @if (item.value.PriceInfo.NetPrice) {
                <div class="col-5">netto</div>
                <div class="col-7 text-end">{{item.value.PriceInfo.NetPrice | price}}</div>
                }
                @if (item.value.PriceInfo.Deposit) {
                <div class="col-6">statiegeld</div>
                <div class="col-6 text-end">{{item.value.PriceInfo.Deposit | price}}</div>
                }
                @if (item.value.PriceInfo.Discount) {
                <div class="col-5">korting</div>
                <div class="col-7 text-end">{{item.value.PriceInfo.Discount | price}}</div>
                }
                @if (item.value.PriceInfo.VAT) {
                  <div class="col-5">BTW</div>
                  <div class="col-7 text-end">{{item.value.PriceInfo.VAT | price}}</div>
                  }
                  @if (item.value.PriceInfo.Currency) {
                    <div class="col-9">munteenheid</div>
                    <div class="col-3 text-end">{{item.value.PriceInfo.Currency}}</div>
                    }
              </div>
            </td>
            <td>{{getFirstDepot(item.value).DepotName}}</td>
            <td>{{getFirstDepot(item.value).DepotID}}</td>
            <td class="text-end"><b>
                <span
                  *ngIf="getFirstDepot(item.value).Availability==availabilityCode.NotInUse">{{getFirstDepot(item.value).Quantity}}</span>
                <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.InStock">op voorraad</span>
                <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.LowOnStock">weinig voorraad</span>
                <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.OutOfStock">geen voorraad</span>
                <span *ngIf="getFirstDepot(item.value).Availability==availabilityCode.SufficientlyInStock">voldoende
                  voorraad</span>
              </b></td>
            <td class="text-end">
              <table class="table table-warning table-bordered table-striped table-sm w-100">
                <tbody>
                  <tr *ngIf="isDateOk(getFirstDepot(item.value).LastUpdated)">
                    <td class="sm-td-l">laatste update</td>
                    <td class="sm-td">{{getFirstDepot(item.value).LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="isDateOk(getFirstDepot(item.value).OrderBeforeDateTime)">
                    <td class="sm-td-l">bestel voor</td>
                    <td class="sm-td">{{getFirstDepot(item.value).OrderBeforeDateTime | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="isDateOk(getFirstDepot(item.value).DeliveryDateTime)">
                    <td class="sm-td-l">levertijd</td>
                    <td class="sm-td">{{getFirstDepot(item.value).DeliveryDateTime | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="getFirstDepot(item.value).ShippingMethod">
                    <td class="sm-td-l">verzendwijze</td>
                    <td class="sm-td">{{getFirstDepot(item.value).ShippingMethod}}</td>
                  </tr>
                  <tr *ngIf="getFirstDepot(item.value).ShippingCosts">
                    <td class="sm-td-l">verzendkosten</td>
                    <td class="sm-td">{{getFirstDepot(item.value).ShippingCosts | price}}</td>
                  </tr>
                  <tr *ngIf="getFirstDepot(item.value).Remark">
                    <td class="sm-td-l">opmerking</td>
                    <td class="sm-td">{{getFirstDepot(item.value).Remark}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngFor="let depot of getOtherDepots(item.value)">
            <td [attr.colspan]="withoutFirstColumn?2:3"></td>
            <td>{{depot.DepotName}}</td>
            <td>{{depot.DepotID}}</td>
            <td class="text-end"><b>
                <span *ngIf="depot.Availability==availabilityCode.NotInUse">{{depot.Quantity}}</span>
                <span *ngIf="depot.Availability==availabilityCode.InStock">op voorraad</span>
                <span *ngIf="depot.Availability==availabilityCode.LowOnStock">weinig voorraad</span>
                <span *ngIf="depot.Availability==availabilityCode.OutOfStock">geen voorraad</span>
                <span *ngIf="depot.Availability==availabilityCode.SufficientlyInStock">voldoende voorraad</span>
              </b></td>
            <td class="text-end">
              <table class="table table-warning table-bordered table-striped w-100">
                <tbody>
                  <tr *ngIf="isDateOk(depot.LastUpdated)">
                    <td class="sm-td-l">laatste update</td>
                    <td class="sm-td">{{depot.LastUpdated | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="isDateOk(depot.OrderBeforeDateTime)">
                    <td class="sm-td-l">bestel voor</td>
                    <td class="sm-td">{{depot.OrderBeforeDateTime | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="isDateOk(depot.DeliveryDateTime)">
                    <td class="sm-td-l">levertijd</td>
                    <td class="sm-td">{{depot.DeliveryDateTime | date : 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="depot.ShippingMethod">
                    <td class="sm-td-l">verzendwijze</td>
                    <td class="sm-td">{{depot.ShippingMethod}}</td>
                  </tr>
                  <tr *ngIf="depot.ShippingCosts">
                    <td class="sm-td-l">verzendkosten</td>
                    <td class="sm-td">{{depot.ShippingCosts | price}}</td>
                  </tr>
                  <tr *ngIf="depot.Remark">
                    <td class="sm-td-l">opmerking</td>
                    <td class="sm-td">{{depot.Remark}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-template>

</ng-template>