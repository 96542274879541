<app-admin-form [title]="'Artikel Informatie'" [object]="true">
  <app-admin-custom-control *ngIf="history?.length" [label]="'Geschiedenis'" [centerLabel]="true">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0">
        <ng-container *ngFor="let item of history">
          <li class="breadcrumb-item"><a href="javascript:void(0);" (click)="doInfo(item)">{{item}}</a></li>
        </ng-container>
      </ol>
    </nav>
  </app-admin-custom-control>
  <app-admin-input [labelCols]="2" [label]="'Artikelnummer'" [(model)]="itemNumber" [focusInput]="focusInput"
    [toUppercase]="true" (keyup.enter)="doInfo(itemNumber)" [dividerKind]="2" [selectOnclick]="true"
    [centerLabel]="true">
    <button class="btn btn-outline-secondary" type="button" (click)="clear()"><i class="fas fa-times"></i></button>
    <button class="btn btn-outline-secondary" type="button" (click)="doInfo(itemNumber)"
      [attr.disabled]="!itemNumber?true:null">
      <span *ngIf="!itemInfoResponse"><i class="far fa-search"></i></span>
      <span *ngIf="itemInfoResponse"><i class="far fa-redo"></i></span>
    </button>
    <span *ngIf="itemInfoResponse?.ItemResults?.length" class="input-group-text">
      <span *ngIf="filteredItemCount && itemInfoResponse.ItemResults.length!==filteredItemCount">{{filteredItemCount}}
        van&nbsp;</span>{{itemInfoResponse.ItemResults.length}}
    </span>
    <ng-template #popTemplate>
      Gebruik *XXX om artikelnummers te zoeken die met XXX beginnen.<br />
      Gebruik *XXX om artikelnummers te zoeken die met XXX eindigen.<br />
      Gebruik *XXX* om artikelnummers te zoeken die XXX bevatten.<br />
      Zet een # voor de zoekterm om op intern artikelnummer te zoeken.<br />
      Zet een & voor de zoekterm om op EANcode/barcode te zoeken.<br />
      Zet een ^ voor de zoekterm om op VrooamArticleId te zoeken.<br />
      Zet een ~ voor de zoekterm om ook in de omschrijvingen te zoeken.<br />
      Zet een $ voor de zoekterm om op het artikelnummer van de leverancier te zoeken.<br />
      Zoek banden via bijvoorbeeld 205/55R16 100V BRIDGESTONE waarbij de snelheidsaanduiding en het merk optioneel zijn.
    </ng-template>
    <span class="input-group-text" [popover]="popTemplate" popoverTitle="Zoek tips" triggers="mouseenter:mouseleave"
      placement="left"><i class="fas fa-info-circle"></i></span>
  </app-admin-input>

  <app-admin-customer-input [label]="'Kies klant'" [(model)]="customer" [noneSelectionLabel]="'Geen klant gekozen.'"
    (modelObjectChange)="doInfo(this.itemNumber)">
    <div class="mt-2">
      <app-customer-warnings [customer]="customer"></app-customer-warnings>
    </div>
  </app-admin-customer-input>
  
  <app-admin-custom-control *ngIf="itemInfoWarnings.anyWarnings((warnings))" [labelCols]="2" [label]="'Waarschuwingen'">
    <app-item-info-warnings [warnings]="warnings"></app-item-info-warnings>
  </app-admin-custom-control>
  <app-admin-custom-control *ngIf="itemInfoResponse || busyItemInfoDoInfo" [labelCols]="2" [label]="'Artikel Info'"
    [busy]="busyItemInfoDoInfo"
    (contextmenu)="mainService.showDebugInfo($event, itemInfoResponse?.Timing, itemInfoResponse)">
    <ng-container *ngIf="itemInfoResponse?.Timing" afterLabel>
      <div>
        <button *ngIf="itemInfoResponse.UsedSql" type="button" class="btn btn-light me-1"
          (click)="mainService.showObjectDialog($event, itemInfoResponse.UsedSql)"><i
            class="fas fa-database"></i></button>
        <button type="button" class="btn btn-light me-1"
          (click)="mainService.showTimingDialog(itemInfoResponse.Timing)"><i class="far fa-stopwatch"></i></button>
        <button type="button" class="btn btn-light" (click)="filterEnabled=!filterEnabled"
          [attr.disabled]="!itemInfoResponse?.ItemResults?.length?true:null">
          <span *ngIf="filterEnabled"><i class="fas fa-filter"></i></span>
          <span *ngIf="!filterEnabled"><i class="far fa-filter"></i></span>
        </button>
      </div>
    </ng-container>
    <app-item-info-response *ngIf="itemInfoResponse" [response]="itemInfoResponse" [filterEnabled]="filterEnabled" [supplierInterfaceData]="supplierInterfaceData"
      (searchAction)="doInfo($event)" (filterResult)="filterResult($event)"></app-item-info-response>
  </app-admin-custom-control>

  <app-admin-custom-control *ngIf="supplierInterfaceResponse || busySupplierInterfaceDoInfo" [labelCols]="2"
    [label]="'Stock Info'" [busy]="busySupplierInterfaceDoInfo"
    (contextmenu)="mainService.showDebugInfo($event, supplierInterfaceResponse?.Timing, supplierInterfaceResponse)">
    <ng-container *ngIf="supplierInterfaceResponse?.Timing" afterLabel>
      <br />
      <button type="button" class="btn btn-light me-2"
        (click)="mainService.showTimingDialog(supplierInterfaceResponse.Timing)"><i
          class="far fa-stopwatch"></i></button>
    </ng-container>
    <app-supplier-interface-response *ngIf="supplierInterfaceResponse" [response]="supplierInterfaceResponse.Response"
      (searchAction)="doInfo($event)"></app-supplier-interface-response>
  </app-admin-custom-control>

  <app-admin-custom-control *ngIf="articleSourceSearchResponse || busyArticleSourceSearch" [labelCols]="2"
    [label]="'Article Source'" [busy]="busyArticleSourceSearch"
    (contextmenu)="mainService.showDebugInfo($event, null, articleSourceSearchResponse)">
    <app-article-source-response *ngIf="supplierInterfaceResponse" [response]="articleSourceSearchResponse"
      (searchAction)="doInfo($event)"></app-article-source-response>
  </app-admin-custom-control>


</app-admin-form>