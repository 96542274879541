import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SupplierInterfaceResponse } from '../../../_models/admin/supplier-interface/supplier.interface.response';
import { CustomerModel } from '../../../_models/common/customer.model';
import { ItemInfoResponse } from '../../../_models/item-info/item.info.response';
import { AdminService } from '../../../_services/admin/admin.service';
import { MainService } from '../../../_services/main.service';
import { ArticleSourceRecord } from '../../../_models/common/article-source/article.source.record';
import { ArticleSourceSearchRequest } from '../../../_models/common/article-source/article.source.search.request';
import { ResponseItem } from '../../../_models/item-info/response.item';
import { ApiService } from '../../../_services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ItemInfoWarnings } from 'app/_models/item-info/item.info.warnings';
import { SupplierInterfaceData } from 'app/_models/admin/supplier-interface/supplier.interface.data';

@Component({
  selector: 'app-admin-item-info',
  templateUrl: './admin-item-info.component.html',
  styleUrls: ['./admin-item-info.component.scss']
})
export class AdminItemInfoComponent implements OnInit {
  itemNumber: string;
  customer = 0;
  busyItemInfoDoInfo: boolean;
  itemInfoResponse: ItemInfoResponse;
  busySupplierInterfaceDoInfo: boolean;
  supplierInterfaceResponse: SupplierInterfaceResponse;
  busyArticleSourceSearch: boolean;
  articleSourceSearchResponse: ArticleSourceRecord[];
  focusInput = new EventEmitter<boolean>();
  itemInfoWarnings = ItemInfoWarnings;
  warnings: ItemInfoWarnings;
  supplierInterfaceData: SupplierInterfaceData;
  history: string[] = [];
  filterEnabled = false;
  filteredItemCount = 0;


  constructor(
    public adminService: AdminService,
    public mainService: MainService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((data: any) => {
        this.warnings = data.warnings;
        this.supplierInterfaceData = data.supplierInterfaceData;
      });
  }

  clear() {
    this.itemNumber = '';
    this.itemInfoResponse = null;
    this.supplierInterfaceResponse = null;
    setTimeout(() => {
      this.focusInput.emit(true);
    }, 0);
  }

  filterResult(result: ResponseItem[]) {
    setTimeout(() => {
      this.filteredItemCount = result?.length ?? 0;
      if (this.filteredItemCount && this.itemInfoResponse.ItemResults.length !== this.filteredItemCount) {
        this.filterEnabled = true;
      }
    }, 0);
  }

  doInfo(itemNumber: string) {
    if (itemNumber) {
      this.history = this.history.remove(itemNumber);
      this.history.push(itemNumber);
      this.itemNumber = itemNumber;
    }
    if (this.itemNumber) {
      this.itemInfoDoInfo(this.itemNumber);
      this.supplierInterfaceDoInfo(22, this.itemNumber);
      this.articleSourceSearch(this.itemNumber);
    }
  }

  itemInfoDoInfo(itemNumber: string) {
    this.busyItemInfoDoInfo = true;

    this.apiService.adminItemInfoDoInfo(this.adminService.currentUid, itemNumber, this.customer)
      .subscribe(result => {
        this.busyItemInfoDoInfo = false;
        this.itemInfoResponse = result;
        if (result?.ItemResults) {
          this.apiService.adminUpdateSupplierAvailabilityItemInfo(this.adminService.currentUid, result, this.customer)
            .subscribe(updated => {
              if (updated) {
                this.itemInfoResponse.ItemResults = updated.ItemResults;
                this.itemInfoResponse.Suppliers = updated.Suppliers;
                this.itemInfoResponse.Timing = updated.Timing;
                this.filterEnabled = !this.filterEnabled;
                setTimeout(() => {
                  this.filterEnabled = !this.filterEnabled;
                }, 0);

              }
            });
        }
      });
  }

  supplierInterfaceDoInfo(interfaceKind: number, itemNumber: string) {
    this.busySupplierInterfaceDoInfo = true;
    this.adminService.supplierInterfaceKindDoInfo(interfaceKind, itemNumber)
      .subscribe(response => {
        this.supplierInterfaceResponse = response;
        this.busySupplierInterfaceDoInfo = false;
        if ((!response?.Response?.IsOk || '*#&^~$'.indexOf(itemNumber.substring(0, 1)) >= 0) && this.itemInfoResponse?.ItemResults?.length === 1 && itemNumber !== this.itemInfoResponse?.ItemResults[0].ItemNumber) {
          this.supplierInterfaceDoInfo(interfaceKind, this.itemInfoResponse?.ItemResults[0].ItemNumber);
        }
      });
  }

  articleSourceSearch(itemNumber: string) {
    if (itemNumber.length > 1) {
      this.busyArticleSourceSearch = true;
      let search = itemNumber;
      const request = new ArticleSourceSearchRequest();
      request.MaxResults = 100;
      if ('&^~$'.indexOf(itemNumber.substring(0, 1)) >= 0) { search = itemNumber.substring(1); }
      if (itemNumber.startsWith('^')) { request.VrmArticleID = +search; }
      if (itemNumber.startsWith('~')) {
        request.ItemGroupDescription = search;
        request.Description = search;
      }
      if (itemNumber.startsWith('&')) { request.Ean = search; }
      request.ItemNumber = search;
      request.ItemNumberSupplier = search;

      this.adminService.articleSourceSearch(request)
        .subscribe(response => {
          this.articleSourceSearchResponse = response;
          this.busyArticleSourceSearch = false;
          if (response && response.length && !this.itemInfoResponse?.ItemResults?.length) this.itemInfoDoInfo(response[0].FactoryCode);
          if (response.length === 0 && this.itemInfoResponse?.ItemResults?.length === 1 && request.ItemNumber !== this.itemInfoResponse?.ItemResults[0].ItemNumber) {
            this.articleSourceSearch(this.itemInfoResponse?.ItemResults[0].ItemNumber);
          }
        });
    }
  }

}
